.App {
  text-align: center;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Anton', sans-serif;
  scroll-behavior: smooth;
body::-webkit-scrollbar {
  width: 0; 
}
}

body {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  min-height: 100vh;
  overflow-y: scroll;
}


