.project-card {
  
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 15px 25px rgba(116, 115, 115, 0.089);
  backdrop-filter: blur(14px);
  border-style: solid;
  border-width: 0.5px 0 0.5px 0;
  border-color: white;
  border-radius: 0.1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  color: #ffffff;
  

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 24px rgba(247, 247, 247, 0.2);
  }

  .icon-container {
    margin-bottom: 15px;
    svg {
      transition: transform 0.3s ease;
    }
  }

  .project-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
    @media (max-width: 576px) {
      font-size: 1.1rem;
    }
  }

  .project-description {
    font-size: 1rem;
    color: #9b9a9a;
     @media (max-width: 576px) {
      font-size: 0.9rem;
    }
  }
  .project-image {
    width: 100%;
    height: auto;
    margin-top: 15px;
    border-radius: 8px;
  }
  &:hover .icon-container svg {
    transform: rotate(20deg) scale(1.1); 
  }
}
