.about-section {
  width: 100%;
  margin-bottom: 40px;
  padding: 2rem;
  color: #fff;
  background-color: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));

  // Increase bottom margin for mobile to separate from the next section
  @media (max-width: 576px) {
    margin-bottom: 60px;
  }

  .about-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 2.5rem;
    }
  }

  .about-subtitle {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  .about-description {
    font-size: 1rem;
    max-width: 600px;
    margin: 0 auto 1rem;
    line-height: 1.4;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 1.25rem;
      max-width: 800px;
      line-height: 1.5;
    }
  }

  .about-interests {
    font-size: 1.25rem;
    color: #b0c4de;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  .typewriter {
    font-weight: bold;
    color: #56ccf2;
    border-right: 2px solid #56ccf2;
    padding-right: 5px;
    animation: blink 0.7s infinite step-end;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.spotify-button {
  display: inline;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

.spotify-iframe-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-top: 10px;
  transition: max-height 0.5s ease-out;

  // Increase bottom margin on mobile for spacing
  margin-bottom: 20px;

  &.show {
    max-height: 352px; // Ensure enough height for iframe when expanded
  }
}

.spotify-description,
.spotify-section {
  font-size: 1rem;
  max-width: 600px;
  margin: 0 auto 1rem;
  line-height: 1.4;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.25rem;
    max-width: 800px;
    line-height: 1.5;
  }
}

.spotify-section {
  padding-top: 50px; // Reduce padding to avoid overflow on small screens
  text-align: center;

  @media (min-width: 768px) {
    padding-top: 200px;
  }
}
